import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from './Icons/CloseIcon';
import BodyEnd from './BodyEnd';

class HelpSupportModal extends React.Component {
  closeHelpSupportModal(e, onClose) {
    if (typeof onClose === 'function') onClose(e);
    e.stopPropagation();
  }

  render() {
    const {
      open,
      onClose,
      children,
      dismissOnOutsideClick,
      type,
      className,
      newHome, // Added here to remove console warning
      ...props
    } = this.props;
    return (
      <BodyEnd>
        <div
          className={`fixed modal-full-width  md:w-full h-modal md:h-full pin-l top-0 modal_background my-8 mx-7 md:m-0 backdrop-blur modal-overlay ${
            this.props.open
              ? 'flex modal-overlay-open z-400'
              : 'hidden modal-overlay-close z--1'
          } `}
          onClick={(e) => {
            if (this.props.open) {
              dismissOnOutsideClick && this.closeHelpSupportModal(e, onClose);
            }
          }}
        >
          <div
            className={`relative w-full md:w-647 h-full md:h-90% md:max-h-736 opacity-100 pl-24 pr-24 md:pl-32 md:pr-30 flex items-start justify-center
                relative overflow-auto sm:m-auto sm:w-647 bg-darkest-grey shadow-xl modal-overlay-child z-400  ${
                  this.props.open
                    ? 'modal-overlay-child-open'
                    : 'modal-overlay-child-close'
                } ${className ? ` ${className}` : ''}`}
            {...props}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className='h-full w-full'>{children}</div>
            <div
              className={`w-32 h-32 absolute pint-t pin-r mt-7 mr-7 md:mt-16 md:mr-10`}
            >
              <button
                className={`h-32 w-32 text-white focus:outline-none`}
                onClick={(e) => {
                  this.closeHelpSupportModal(e, onClose);
                }}
              >
                <CloseIcon className='h-16 w-16' />
              </button>
            </div>
          </div>
        </div>
      </BodyEnd>
    );
  }
}

HelpSupportModal.propTypes = {
  /** Current state of this HelpSupportModal */
  open: PropTypes.bool,
  /** Handler function for the HelpSupportModal close button. This function will be invoked with the click event received on the close button */
  onClose: PropTypes.func,
  /** Whether to close this HelpSupportModal when the user clicks outside of the HelpSupportModal. */
  dismissOnOutsideClick: PropTypes.bool,
  /** Type of the HelpSupportModal. */
  type: PropTypes.string,
};

HelpSupportModal.defaultProps = {
  open: false,
  onClose: null,
  dismissOnOutsideClick: true,
  type: 'default',
};

export default HelpSupportModal;
