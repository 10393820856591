import { Auth } from 'aws-amplify';
import { getSegmentBaseProps } from '../analytic/segment/utils';
import defaultConfig from '../constants/defaultConfig';
import _ from 'lodash';
import moment from 'moment';

function logError(payload) {
  if (window.analytics?.track) {
    const logBaseProps = getSegmentBaseProps();
    window.analytics.track('Refresh JWT Token', {
      ...payload,
      ...logBaseProps,
    });
  }
}

export async function getJwtToken(sourceTrigger, accessAuthorization = false) {
  let idToken = null;
  const userName = _.get(
    localStorage,
    `CognitoIdentityServiceProvider.${defaultConfig.awsUserPoolWebClientId}.LastAuthUser`,
  );
  const currentIdJwtToken = _.get(
    localStorage,
    `CognitoIdentityServiceProvider.${defaultConfig.awsUserPoolWebClientId}.${userName}.idToken`,
  );
  const currentTime = moment().valueOf() / 1000; // convert current Epoch time to seconds

  return await Auth.currentSession()
    .then((result) => {
      idToken = _.get(result, 'idToken');
      let idJwtToken = _.get(idToken, 'jwtToken');
      const accessToken = _.get(result, 'accessToken');
      const expiryTime = _.get(idToken, 'payload.exp'); // expiry time in seconds(Epoch)
      let accessJwtToken = _.get(accessToken, 'jwtToken');

      if (
        idJwtToken !== currentIdJwtToken ||
        expiryTime < currentTime ||
        !expiryTime
      ) {
        let severity = null;
        let statusCode = null;

        if (_.isEmpty(idJwtToken) || expiryTime < currentTime || !expiryTime) {
          severity = 'ERROR';
          statusCode = 'FAIL';
          const reason =
            expiryTime < currentTime || !expiryTime
              ? 'JWT Token has expired'
              : 'JWT Token does not exist';
          logError({
            event: {
              eventName: 'refresh_jwt_token',
              severity: severity,
              message: 'Refresh Token',
              reason: reason,
              statusCode: statusCode,
              sourceTrigger: sourceTrigger,
            },
          });
        }
      }
      if (accessAuthorization) {
        return {
          idJwtToken,
          accessJwtToken,
        };
      } else {
        return idJwtToken;
      }
    })
    .catch((error) => {
      logError({
        event: {
          eventName: 'refresh_jwt_token',
          severity: 'ERROR',
          message: 'Refresh Token',
          reason: 'Fatal error',
          error,
          statusCode: 'FAIL',
          sourceTrigger: sourceTrigger,
        },
      });
      return null;
    });
}
