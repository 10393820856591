import React from 'react';
import PropTypes from 'prop-types';
import BackIcon from './Icons/BackIcon';

const HeaderNavBack = ({
  label,
  onBackClicked,
  oneThirdWidth,
  textClass,
  textContainerClass,
  ...props
}) => (
  <div
    className={`px-7 w-${
      oneThirdWidth ? '1/3' : 'full'
    } flex justify-start ${textContainerClass} header-nav-home-btn`}
    {...props}
  >
    <button
      className={`os-transition focus:outline-none ${textClass} flex flex-no-wrap items-center back-link`}
      onClick={(e) => {
        e.stopPropagation();
        onBackClicked(e);
      }}
    >
      <div className='h-32'>
        <BackIcon />
      </div>
      <p className='text-sm font-MarkProHeavy leading-2.29'>{label}</p>
    </button>
  </div>
);

HeaderNavBack.propTypes = {
  /** Handler function for this button. This function will be invoked with the click event received on the button */
  onBackClicked: PropTypes.func,
  /** Label for this back button */
  label: PropTypes.string,
  /** Flag for the component's width */
  oneThirdWidth: PropTypes.bool,
  textContainerClass: PropTypes.string,
};

HeaderNavBack.defaultProps = {
  label: 'Back',
  onBackClicked: () => {},
  oneThirdWidth: false,
  textClass: 'text-white hover:text-teal',
  textContainerClass: '',
};

export default HeaderNavBack;
