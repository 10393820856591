import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment';
import DownIcon from '../Icons/DownIcon';
import IconSmallRight from '../Icons/IconSmallRight';
import FixtureTeam from './FixtureTeam';
import FixtureStatus from '../FixtureStatus';
import FixtureScore from '../FixtureScore';

class GTCollapsible extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  findTeamShortCode(teamID, teamlist) {
    const team = teamlist.find((e) => e.TeamID === teamID);
    const teamShortCode = team && team.teamShortCode ? team.teamShortCode : '';
    return teamShortCode;
  }

  renderStatusOrScore = (match) => {
    if (match.Period === 'PreMatch') {
      return (
        <FixtureStatus
          label={moment
            .utc(match.MatchDateTime)
            .local()
            .format('hh:mm')}
          type='time'
          mini
        />
      );
    } else if (match.Period === 'FullTime') {
      return (
        <FixtureScore
          score1={match.HomeScore}
          score2={match.AwayScore}
          boldLeft={
            Number(match.HomeScore) > Number(match.AwayScore) ? true : false
          }
          boldRight={
            Number(match.AwayScore) > Number(match.HomeScore) ? true : false
          }
          mini
          loadMatchHighlights={this.props.loadMatchHighlights}
          competitionId={this.props.competitionId}
          season={this.props.season}
          matchId={match.MatchID}
        />
      );
    }
  };

  reverseMatchesOrder = (matches) => {
    const reversedMatches = _.sortBy(matches, function(match) {
      return match.MatchDateTime;
    });

    return reversedMatches;
  };

  renderPastMatches = (matches, navigationHandler) => {
    if (matches && matches.data && matches.data.length !== 0) {
      const matchesData = this.reverseMatchesOrder(matches.data);

      return matchesData.map((match, i) => {
        const matchDate = moment
          .utc(match.MatchDateTime)
          .local()
          .format('ddd DD MMM YYYY');

        const homeTeamImg = _.get(match, 'HomeTeamImageURL', '');
        const awayTeamImg = _.get(match, 'AwayTeamImageURL', '');

        return (
          <li
            key={i}
            className='relative border-b border-medium-dark-grey py-10'
          >
            <span className='text-center text-white text-xs font-MarkPro mb-10 font-normal block'>
              {matchDate}
            </span>

            <div className='flex flex-no-wrap justify-center items-center'>
              <div className='team-one flex-1 text-right'>
                <FixtureTeam
                  imgUrl={homeTeamImg}
                  team={match.HomeTeamName}
                  reverse
                  teamShortCode={this.findTeamShortCode(
                    match.HomeTeamID,
                    this.props.sectionTables,
                  )}
                />
              </div>

              <div className='score-card mx-7 flex-no-grow flex-no-shrink w-46'>
                {this.renderStatusOrScore(match)}
              </div>

              <div className='team-two flex-1'>
                <FixtureTeam
                  imgUrl={awayTeamImg}
                  team={match.AwayTeamName}
                  teamShortCode={this.findTeamShortCode(
                    match.AwayTeamID,
                    this.props.sectionTables,
                  )}
                />
              </div>
            </div>

            <button
              onClick={() => navigationHandler(match.MatchID)}
              className='text-white absolute pin-r os-pin-t-50 -mt-18 outline-none focus:outline-none active:outline-none'
            >
              <IconSmallRight />
            </button>
          </li>
        );
      });
    }
    return null;
  };

  renderPreMatches = (matches, navigationHandler) => {
    if (matches && matches.data && matches.data.length !== 0) {
      const matchesData = this.reverseMatchesOrder(matches.data);

      return matchesData.map((match, i) => {
        const matchDate = moment
          .utc(match.MatchDateTime)
          .local()
          .format('ddd DD MMM YYYY');

        const homeTeamImg = _.get(match, 'HomeTeamImageURL', '');
        const awayTeamImg = _.get(match, 'AwayTeamImageURL', '');

        return (
          <li
            key={i}
            className='relative border-b border-medium-dark-grey py-10'
          >
            <span className='text-center text-white text-xs font-MarkPro mb-10 font-normal block'>
              {matchDate}
            </span>

            <div className='flex flex-no-wrap justify-center items-center'>
              <div className='team-one flex-1 text-right'>
                <FixtureTeam
                  imgUrl={homeTeamImg}
                  team={match.HomeTeamName}
                  reverse
                  teamShortCode={this.findTeamShortCode(
                    match.HomeTeamID,
                    this.props.sectionTables,
                  )}
                />
              </div>

              <div className='score-card mx-7 flex-no-grow flex-no-shrink w-46'>
                {this.renderStatusOrScore(match)}
              </div>

              <div className='team-two flex-1'>
                <FixtureTeam
                  imgUrl={awayTeamImg}
                  team={match.AwayTeamName}
                  teamShortCode={this.findTeamShortCode(
                    match.AwayTeamID,
                    this.props.sectionTables,
                  )}
                />
              </div>
            </div>

            <button
              onClick={() => navigationHandler(match.MatchID)}
              className='text-white absolute pin-r os-pin-t-50 -mt-18 outline-none focus:outline-none active:outline-none'
            >
              <IconSmallRight />
            </button>
          </li>
        );
      });
    }
    return null;
  };

  render() {
    const { navigationHandler } = this.props;

    return (
      <section className='gt-collapsible-section w-full'>
        <div
          className={`${
            this.state.expanded ? 'expanded' : 'collapsed overflow-hidden'
          } gt-collapsible-content w-full  px-16`}
        >
          <div className='text-center text-white'>
            <h1 className='font-MarkProHeavy text-base mt-16 mb-6'>Matches</h1>
          </div>

          <ul className='list-reset m-0 p-0 text-center'>
            {this.renderPastMatches(
              this.props.collapsedResultsData,
              navigationHandler,
            )}
            {this.renderPreMatches(this.props.collapsedData, navigationHandler)}
          </ul>
        </div>

        <header className='gt-collapsible-header'>
          <div
            onClick={() => this.setState({ expanded: !this.state.expanded })}
            className='flex items-center justify-center cursor-pointer'
          >
            <div
              className={`flex-none ${
                this.state.expanded ? 'os-rotate-180' : ''
              }`}
            >
              <DownIcon width='32' height='32' />
            </div>
          </div>
        </header>
      </section>
    );
  }
}

export default GTCollapsible;
