import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import BackIcon from './Icons/BackIcon';

class MDTabNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSlider: false,
      count: 0,
      showRight: true,
      index: 0, // index of first card that showing on the page
      offset: 0, // px to transform
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.showSlideHandler);
    this.showSlideHandler();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.showSlideHandler);
  }

  callbackHandler = (callback, e) =>
    typeof callback === 'function' ? callback(e) : '';

  showSlideHandler = () => {
    if (this.tabsEl) {
      const showSlider = this.tabsEl.scrollWidth > this.tabsEl.clientWidth;
      this.setState({
        showSlider: showSlider,
      });
    }
  };

  getVisibleItems() {
    const list = ReactDOM.findDOMNode(this.tabsEl);

    if (list) {
      const len = list.children.length;
      const offsetLeft = Math.abs(this.state.offset);
      const clientWidth = list.clientWidth;
      let start;
      let i;
      let width = 0;

      for (start = 0; start < len; start++) {
        if (list.children[start].offsetLeft >= offsetLeft) {
          break;
        }
      }
      for (i = start; i < len && width < clientWidth; i++) {
        if (width + list.children[i].clientWidth > clientWidth) {
          break;
        }
        width += list.children[i].clientWidth;
      }
      return i - start;
    }
    return 0;
  }

  getOffset(index) {
    let list = ReactDOM.findDOMNode(this.tabsEl);
    let offset = 0;

    if (index <= 0 || !list) {
      /*
       * offset is 0 in the follow case:
       * 1. input index is 0 mean nothing to scroll;
       * 2. the list or not list;
       */
      return 0;
    }

    for (let i = 0; i < index && i < list.children.length; i++) {
      offset += list.children[i].clientWidth;
    }

    return -Math.min(offset, list.scrollWidth - list.clientWidth);
  }

  onPrevious(e) {
    const index = Math.max(0, this.state.index - this.getVisibleItems());
    e.preventDefault();
    this.setState({
      index: index,
      offset: this.getOffset(index),
    });
  }

  onNext(e) {
    let list;
    let visibleItems;
    let index;

    e.preventDefault();
    list = ReactDOM.findDOMNode(this.tabsEl);
    visibleItems = this.getVisibleItems();
    index = Math.min(
      this.state.index + visibleItems,
      list.children.length - visibleItems,
    );

    this.setState({
      index: index,
      offset: this.getOffset(index),
    });
  }

  scrollTo(direction) {
    const navList = this.tabsEl;
    const count =
      direction === 'left' ? this.state.count - 1 : this.state.count + 1;
    const showRight =
      direction === 'left'
        ? navList.scrollWidth !== navList.scrollLeft - navList.clientWidth
        : navList.scrollWidth > navList.scrollLeft + navList.clientWidth;
    // this.setState({ count, showRight }, () => this.sideScroll(navList, direction, 25, 70, 10));
    this.setState({ count, showRight }, () =>
      this.sideScroll(navList, direction, 25, 100, 10),
    );
  }

  sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    const leftStepAdjuster = 10;
    const rightStepAdjuster = 10;
    let slideTimer = setInterval(() => {
      if (direction === 'left') {
        element.scrollLeft -= step + leftStepAdjuster;
      } else {
        element.scrollLeft += step + rightStepAdjuster;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
  render() {
    const { showSlider } = this.state;
    const { children, callback } = this.props;
    return (
      <div className='w-full h-48  bg-black flex flex-no-wrap items-center relative justify-center xl:justify-start border-b border-teal'>
        <div
          className={`w-full flex flex-no-wrap items-center relative text-center border-b border-teal ${
            showSlider ? ' h-99p' : ''
          } ${
            showSlider && this.state.index > 0 ? 'md-tab-gradient-left' : 'pl-0'
          } ${
            this.state.index + this.getVisibleItems() !== children.length
              ? 'md-tab-gradient-right '
              : ''
          }  `}
        >
          <React.Fragment>
            {showSlider && (this.state.index > 0 || this.state.count > 0) ? (
              <BackIcon
                className='flex-none icon-left hover:text-teal cursor-pointer pin-l z-3'
                color='white'
                onClick={(e) => {
                  this.onPrevious(e);
                  this.scrollTo('left');
                }}
              />
            ) : null}

            <ul
              className={`flex min-w-64 p-0 items-center overflow-hidden relative leading-base list-reset`}
              ref={(node) => {
                this.tabsEl = node;
                if (!this.initialResize) {
                  this.initialResize = true;
                  this.showSlideHandler();
                }
              }}
              onClick={(e) => this.callbackHandler(callback, e)}
            >
              {children}
            </ul>

            {showSlider &&
            this.state.index + this.getVisibleItems() !== children.length ? (
              <BackIcon
                className='flex-none icon-right hover:text-teal cursor-pointer pin-r z-3'
                color='white'
                onClick={(e) => {
                  this.onNext(e);
                  this.scrollTo('right');
                }}
              />
            ) : null}
          </React.Fragment>
        </div>
      </div>
    );
  }
}

MDTabNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

MDTabNav.defaultProps = {};

export default MDTabNav;
