import { API } from 'aws-amplify';
import { getFiltersByData } from '../SearchResults/utils';
import { analyticSearchResponded } from '../__analytics__';
import defaultConfig from 'constants/defaultConfig';

async function assetSearchEndpoint({ query, loggedIn, postfix = '' }) {
  const prefix = loggedIn ? '' : '/public';
  const path = `${prefix}/assets${postfix}`;
  const params = {
    queryStringParameters: query,
    timeout: defaultConfig.defaultApiTimeout,
  };

  return API.get('fe-api-search', path, params);
}

export async function reqSearch(text, config) {
  return await assetSearchEndpoint({
    query: { text },
    loggedIn: config.loggedIn,
    postfix: '/web',
  });
}

export async function reqSearchSuggestions(text, config) {
  return await assetSearchEndpoint({
    query: { text },
    loggedIn: config.loggedIn,
    postfix: '/suggestions',
  });
}

const storageName = 'searchHistory';
export function setStoreSearchHistory(text) {
  const data = JSON.parse(localStorage.getItem(storageName)) || [];
  let result = [];
  if (Array.isArray(data)) {
    const textTrimmed = text && text.trim().toLowerCase();

    if (textTrimmed) {
      if (!data.includes(textTrimmed)) {
        const newData = data.slice(0, 4);
        result = [textTrimmed, ...newData];
      } else {
        const index = data.indexOf(textTrimmed);

        result = [
          textTrimmed,
          ...data.slice(0, index),
          ...(index + 1 < data.length
            ? data.slice(index + 1, data.length)
            : []),
        ];
      }

      localStorage.setItem(storageName, JSON.stringify(result));
    } else {
      result = data;
    }
  }
  return result;
}

export function getStoreSearchHistory() {
  const data = JSON.parse(localStorage.getItem(storageName)) || [];
  if (data.length > 5) {
    const newData = data.slice(0, 5);
    localStorage.setItem(storageName, JSON.stringify(newData));
    return newData;
  }

  return data;
}

export function removeStoreSearchHistory(index) {
  const data = JSON.parse(localStorage.getItem(storageName)) || [];
  let result = [];
  if (Array.isArray(data)) {
    if (index > -1) {
      data.splice(index, 1);
      result = data;
      localStorage.setItem(storageName, JSON.stringify(data));
    } else {
      // remove all
      localStorage.removeItem(storageName);
    }
  }

  return result;
}

export const handleRequest = async ({
  value,
  dispatch,
  searchDispatch,
  config,
}) => {
  try {
    searchDispatch({ type: 'TOGGLE_SEARCH_LOADING', data: true });
    const res = await reqSearch(value, config);
    const { result = [] } = res || {};
    await analyticSearchResponded(dispatch, {
      query: value,
      assets: result,
      filters: getFiltersByData(result),
      statusCode: '200',
    });

    searchDispatch({ type: 'SET_SEARCH_DATA', data: { data: result } });

    if (result.length) {
      searchDispatch({
        type: 'SET_HISTORY_DATA',
        data: {
          data: setStoreSearchHistory(value),
        },
      });
    }
  } catch (error) {
    const { response: { status = '400' } = {} } = error || {};
    await analyticSearchResponded(dispatch, {
      query: value,
      statusCode: status,
    });
  } finally {
    searchDispatch({ type: 'TOGGLE_SEARCH_LOADING', data: false });
  }
};
