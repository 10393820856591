import { API } from 'aws-amplify';

export function saveStreamSession(
  sessionId,
  device,
  app,
  attributes,
  videoPlayer,
  asset,
  browser,
  screen,
  userPreference,
  errors,
) {
  const option = {
    body: {
      sessionId,
      device,
      app,
      attributes,
      videoPlayer,
      asset,
      browser,
      screen,
      userPreference,
      errors,
    },
    headers: {},
  };

  return new Promise((resolve, reject) => {
    API.post('fe-api-streamSessionManager', `/sessions`, option)
      .then((result) => {
        if (result.data) {
          resolve(result.data);
        } else {
          reject(result.error);
        }
      })
      .catch((error) => {
        console.log(`saveStreamSession error: ${error}`);
        reject(error);
      });
  });
}
