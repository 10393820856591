import React from 'react';
import PropTypes from 'prop-types';

const large = 'large';
const small = 'small';

class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      disabled: props.disabled,
    };
  }

  handleToggle(e) {
    if (this.state.disabled) return;
    //Toggle state on click
    let nextState = !this.state.status;
    this.setState({ status: nextState });
    if (typeof this.props.changed === 'function') this.props.changed(nextState);
  }

  buttonBorderColor = (disabled, status) =>
    (disabled && 'opacity-25 border-white') ||
    (status ? 'border-green' : 'border-white');
  buttonBgColor = (disabled, status) =>
    (disabled && 'bg-white') || (status ? 'bg-green' : 'bg-white');
  buttonWidth = (type) => (type === small ? 'w-25 h-16' : 'w-40 h-24');
  buttonToggleWidth = (type) =>
    type === small ? 'w-8 h-8 mt-2 mx-4' : 'w-12 h-12 mt-4 mx-4';
  transitionToggle = (type) => (type === small ? 'os-tx-65p' : 'os-tx-full');

  render() {
    const { label, type, className, showStatus, showAllLabels } = this.props;
    const { status, disabled } = this.state;
    return (
      <div
        className={`flex flex-no-wrap items-center${
          className ? ` ${className}` : ''
        }`}
      >
        {showAllLabels && (
          <p
            className={`text-xs mr-10 font-MarkProBold uppercase ${
              !status ? 'text-white' : 'text-light-grey'
            }`}
          >
            Off
          </p>
        )}
        {label && <p className='text-white text-xs mr-10'>{label}</p>}
        <div className={`mb-2 ${this.buttonWidth(type)} inline-block relative`}>
          <div
            className={`${this.buttonBorderColor(
              disabled,
              status,
            )} os-transition relative border-2 rounded-2xl border-grey cursor-pointer ${this.buttonWidth(
              type,
            )} px-2`}
          >
            <span
              className={`os-transition os-transitionproperty-all ${
                status ? `${this.transitionToggle(type)}` : ''
              } ${this.buttonBgColor(
                disabled,
                status,
              )} absolute rounded-full ${this.buttonToggleWidth(
                type,
              )} shadow os-shadow-toggle`}
            ></span>
          </div>
          <input
            className={`absolute cursor-pointer opacity-0 ${this.buttonWidth(
              type,
            )} pin-t`}
            type='checkbox'
            checked={status}
            onChange={(e) => this.handleToggle(e)}
          />
        </div>
        {showStatus && (
          <p
            className={`text-xs font-MarkProBold ml-4 ${
              status ? 'text-green' : 'text-white'
            }`}
          >
            {status ? 'ON' : 'OFF'}
          </p>
        )}
        {showAllLabels && (
          <p
            className={`text-xs ml-10 font-MarkProBold uppercase ${
              status ? 'text-green' : 'text-light-grey'
            }`}
          >
            On
          </p>
        )}
      </div>
    );
  }
}

Switch.propTypes = {
  /** Click handler function for this switch. This function will be invoked with the click event received on the switch */
  onClick: PropTypes.func,
  /** Focus handler function for this switch. This function will be invoked with the focus event received on the switch */
  onFocus: PropTypes.func,
  /** Blur handler function for this switch. This function will be invoked with the blur event received on the switch */
  onBlur: PropTypes.func,
  /** State of the switch */
  status: PropTypes.bool,
  /** Whether the switch is disabled or not. Defaults to false.**/
  disabled: PropTypes.bool,
  /** Handler function to invoke when a state change is detected. This function will be invoked with current status of the switch**/
  changed: PropTypes.func,
  /** Label for this switch*/
  label: PropTypes.string,
  /** Type of this switch*/
  type: PropTypes.oneOf([large, small]),
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Whether to show the current status of the switch */
  showStatus: PropTypes.bool,
  /** Whether to show both On and Off labels */
  showAllLabels: PropTypes.bool,
};

Switch.defaultProps = {
  status: false,
  disabled: false,
  onClick: null,
  changed: null,
  onFocus: null,
  onBlur: null,
  label: null,
  type: small,
  className: null,
  showStatus: false,
  showAllLabels: false,
};

export default Switch;
