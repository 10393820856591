import React from 'react';
import PropTypes from 'prop-types';
import NotificationIcon from '../Icons/NotificationIcon';
import Counter from './Counter';

const iconColor = (active) => (active ? 'text-pink' : 'text-white');

const NotificationIndicator = ({ value, active, className, ...props }) => (
  <div
    className={`cursor-pointer h-32 os-transition os-transitionproperty-all lg:pr-20${iconColor(
      active,
    )} inline-block relative${className ? ` ${className}` : ''}`}
    {...props}
  >
    <NotificationIcon
      className={`
        mb-48
      `}
    />
    {value && <Counter className='absolute top-0 pin-r' value={value} />}
  </div>
);

NotificationIndicator.propTypes = {
  /** value as string/number to be passed on to display counter, if null counter is not shown */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** if active true, else false */
  active: PropTypes.bool,
  /** tailwind css classes*/
  className: PropTypes.string,
};

NotificationIndicator.defaultProps = {
  value: null,
  active: false,
  className: null,
};

export default NotificationIndicator;
