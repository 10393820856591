import React from 'react';

const SVGImage = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='211'
    height='224'
    viewBox='0 0 211 224'
  >
    <g fill='none' fill-rule='evenodd'>
      <path
        fill='#05cedd'
        fill-rule='nonzero'
        d='M69 2v28h74V2H69zm-2-2h78v32H67V0z'
      />
      <path fill='#05cedd' d='M0 122h211v2H0zM0 0h211v2H0z' />
      <path fill='#05cedd' d='M0 0h2v224H0zM209 0h2v224h-2z' />
      <g fill='#FFF' transform='translate(86 103)'>
        <path
          fill-rule='nonzero'
          d='M20 38c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18zm0 2C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20z'
        />
        <circle cx='20' cy='20' r='2.5' stroke='#FFF' strokeWidth='3' />
      </g>
      <g fill='#05cedd' fill-rule='nonzero'>
        <path d='M199.828 2H209v9.172L199.828 2zM211 16V0h-16l16 16zM11.172 2H2v9.172L11.172 2zM0 16V0h16L0 16z' />
      </g>
    </g>
  </svg>
);

const PassAccuracyGraphic = ({ ownHalf, oppHalf }) => (
  <div className='h-256 w-full flex flex-col items-center justify-start bg-transparent'>
    <span className='text-sm text-white font-MarkPro leading-1.14 mb-16'>
      Pass Accuracy by Area (%)
    </span>
    <div className='relative h-224 w-211 flex items-center justify-center'>
      <SVGImage />
      <div className='h-full w-full absolute top-0 flex flex-col items-center pt-44'>
        <span className='text-xl text-white font-MarkProHeavy leading-none'>
          {oppHalf}
        </span>
        <span className='text-white text-xs mt-4 leading-loose'>Opp. Half</span>

        <span className='text-xl text-white font-MarkProHeavy leading-none mt-68'>
          {ownHalf}
        </span>

        <span className='text-white text-xs mt-4 leading-loose'>Own Half</span>
      </div>
    </div>
  </div>
);

export default PassAccuracyGraphic;
