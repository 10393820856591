import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown-now';

import durationToTime from '../../../../../utils/time';
import ArticleIcon from '../../../ui-kit/Icons/ArticleIcon';
import Picture from '../../../Picture';
import FreemiumBadge from '../../../ui-kit/Icons/FreemiumBadge';
import { shouldShowFreeBadge } from 'utils/getAssetData';
import { getDefaultImage1280x720 } from 'utils';

export function DurationStatus({
  isLive: isLiveProps,
  eventDate,
  localTime,
  duration,
  style,
  className = '',
  disableCountdown = false,
  assetTypeName,
}) {
  const notStartedYet = localTime.isBefore(eventDate);
  const [isLive, setIsLive] = useState(
    isLiveProps && !localTime.isBefore(eventDate),
  );
  useEffect(() => {
    setIsLive(isLiveProps && !localTime.isBefore(eventDate));
  }, [isLiveProps, eventDate, localTime]);

  if (
    (!isLiveProps && !notStartedYet && !duration) ||
    assetTypeName === 'article'
  ) {
    return null;
  }

  if (isLive) {
    return (
      <div
        style={style}
        className={
          'font-MarkProHeavy leading-1.6 text-live-red text-2xs py-4 px-8 ' +
          className
        }
      >
        <span className='inline-block live-dot h-6 w-6 rounded-full bg-live-red mr-4' />
        LIVE
      </div>
    );
  }

  if (notStartedYet) {
    return (
      <span
        style={style}
        className={`
          countdown-wrap
          font-MarkProBold
          leading-1.6
          text-2xs
          text-white
          ${className}
        `}
      >
        <Countdown
          date={eventDate.format('llll')}
          onComplete={() => setIsLive(true)}
          renderer={({ days, hours, minutes, seconds, completed }) => {
            if (!disableCountdown && !completed && days < 1 && hours < 12) {
              return (
                <div className='py-4 px-8'>
                  <span className='countdown-label-text'>Coverage in </span>
                  <span className='os-tabular-nums'>
                    {hours}:{minutes}:{seconds}
                  </span>
                </div>
              );
            }

            return null;
          }}
        />
      </span>
    );
  }

  const isReplayGame = assetTypeName === 'game' && duration;
  return (
    <span
      style={style}
      className={`font-MarkProBold text-2xs leading-base py-4 px-8 ${
        duration && !isReplayGame ? '' : 'hidden'
      } ${className}`}
    >
      {durationToTime(duration)}
    </span>
  );
}

function AssetImage({
  isPremiumUser,
  lazyLoaded,
  isLive,
  localTime,
  drmProtected,
  labeledAsFree,
  eventDate,
  duration,
  imageUrl,
  imageUrls,
  title,
  isArticle,
  imageUrl_16_9,
  dontShowLabels,
  className,
  strictClassName,
  heightClassName,
  children,
  assetTypeName,
  isLoggedIn,
  asset,
}) {
  const imgSrc = getDefaultImage1280x720(imageUrl_16_9);
  return (
    <div
      style={{ content: '' }}
      className={
        strictClassName ||
        `absolute w-full leading-zero os-top-0 os-left-0 h-0 overflow-hidden block ${className} ${heightClassName ||
          'pb-56-25%'}`
      }
    >
      {!!lazyLoaded && (
        <Picture
          alt={title}
          src={imgSrc}
          srcSet={imageUrls}
          imageUrl_16_9={imageUrl_16_9}
          className={heightClassName || `w-full h-auto`}
        />
      )}
      {!dontShowLabels && (
        <>
          <div className='text-white ml-10 mt-10 rounded-md absolute top-0 pin-l h-24 bg-black80 text-center inline-flex items-center'>
            <DurationStatus
              isLive={isLive}
              duration={duration}
              eventDate={eventDate}
              localTime={localTime}
              assetTypeName={assetTypeName}
            />
          </div>
          {shouldShowFreeBadge(isPremiumUser, isLoggedIn, asset) ? (
            <FreemiumBadge />
          ) : null}
        </>
      )}

      {!!isArticle && (
        <div
          className={`absolute z-49 os-purple-gradient os-bottom-0 os-right-0 w-80 ${heightClassName ||
            'h-80'}`}
        >
          <ArticleIcon
            className='absolute z-50 opacity-100 os-bottom-8 os-right-8'
            width={24}
            height={24}
            viewBox={`0 0 24 24`}
          />
        </div>
      )}

      {children}
    </div>
  );
}

AssetImage.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  liveBroadcastTime: PropTypes.string,
  dontShowLabels: PropTypes.bool,
  className: PropTypes.string,
  assetTypeName: PropTypes.string,
};

AssetImage.defaultProps = {
  imageUrl: '',
  title: '',
  liveBroadcastTime: '',
  dontShowLabels: false,
  className: '',
  assetTypeName: '',
};

export default AssetImage;
