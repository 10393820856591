import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableBodyRow from './TableBodyRow';

class RegularTable extends Component {
  static propTypes = {
    /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
    className: PropTypes.string,
    /** Headers for the table **/
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Content for the table **/
    data: PropTypes.array,
    /** Results data */
    sectionResults: PropTypes.array,
    /** Gradient feature */
    hasGradient: PropTypes.bool,
    /** Table Caption */
    caption: PropTypes.string,
    /** Full Table Link */
    fullTableLink: PropTypes.string,
  };

  static defaultProps = {
    headers: [
      'POS',
      'P',
      'W',
      'D',
      'L',
      'GF',
      'GA',
      'GD',
      'PTS',
      'Form',
      'Next',
    ],
    data: [
      {
        TeamID: '43',
        Position: '1',
        TeamImageUrl: '/TeamIcons/43.png',
        TeamDisplayName: 'Manchester City',
        TeamShortName: 'Man City',
        TeamCodeName: 'MCI',
        Played: '9',
        HomeWon: '5',
        HomeDraw: '0',
        HomeLost: '0',
        HomeGoalFor: '18',
        HomeGoalAgainst: '2',
        AwayWon: '2',
        AwayDraw: '2',
        AwayLost: '0',
        AwayGoalFor: '8',
        AwayGoalAgainst: '1',
        GoalDifference: '23',
        Points: '23',
        Won: '7',
        Draw: '2',
        Lost: '0',
        GroupTable: '',
        GroupId: '',
      },
      {
        TeamID: '14',
        Position: '2',
        TeamImageUrl: '/TeamIcons/14.png',
        TeamDisplayName: 'Liverpool',
        TeamShortName: 'Liverpool',
        TeamCodeName: 'LIV',
        Played: '9',
        HomeWon: '3',
        HomeDraw: '1',
        HomeLost: '0',
        HomeGoalFor: '8',
        HomeGoalAgainst: '0',
        AwayWon: '4',
        AwayDraw: '1',
        AwayLost: '0',
        AwayGoalFor: '8',
        AwayGoalAgainst: '3',
        GoalDifference: '13',
        Points: '23',
        Won: '7',
        Draw: '2',
        Lost: '0',
        GroupTable: '',
        GroupId: '',
      },
      {
        TeamID: '8',
        Position: '3',
        TeamImageUrl: '/TeamIcons/8.png',
        TeamDisplayName: 'Chelsea',
        TeamShortName: 'Chelsea',
        TeamCodeName: 'CHE',
        Played: '9',
        HomeWon: '3',
        HomeDraw: '2',
        HomeLost: '0',
        HomeGoalFor: '12',
        HomeGoalAgainst: '6',
        AwayWon: '3',
        AwayDraw: '1',
        AwayLost: '0',
        AwayGoalFor: '8',
        AwayGoalAgainst: '1',
        GoalDifference: '13',
        Points: '21',
        Won: '6',
        Draw: '3',
        Lost: '0',
        GroupTable: '',
        GroupId: '',
      },
    ],
    sectionResults: [],
    hasGradient: false,
    activePosition: 2,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSummary: false,
    };

    this.showSummary = this.showSummary.bind(this);
    this.renderRows = this.renderRows.bind(this);
  }

  showSummary = (showSummary) => {
    if (this.state.showSummary !== showSummary) {
      this.setState({ showSummary });
    }
  };

  getMatchesPlayed(teamID) {
    let matchesPlayed = _.map(this.props.sectionResults, function(o) {
      if (o.HomeTeamID === teamID || o.AwayTeamID === teamID) {
        return o;
      }
    });
    matchesPlayed = _.without(matchesPlayed, undefined);
    return matchesPlayed;
  }

  renderRows(data, clickHandler, teams, isGroupTableRow) {
    if (data) {
      return data.map((individualRowData, index) => {
        let className = '';
        if (isGroupTableRow && this.props.type === 'competitionTable') {
          if (index === 0) {
            className = 'group-table-highlighted';
          }
        }

        if (isGroupTableRow && this.props.type === 'teamTable') {
          if (individualRowData.TeamID === this.props.teamID) {
            className = 'group-table-highlighted';
          }
        }

        return (
          <TableBodyRow
            key={individualRowData.TeamID}
            data={individualRowData}
            fixtures={this.props.fixtures}
            results={this.props.sectionResults}
            sectionTables={
              this.props.hasGradient
                ? this.props.fullTableData
                : this.props.data
            }
            onRowClick={clickHandler}
            optaMetaData={this.props.opta}
            teams={teams}
            isGroupTableRow={isGroupTableRow}
            className={className}
          />
        );
      });
    }
    return null;
  }

  render() {
    const { onRowClick } = this.props;
    const teamsWithNextMatch = this.props.data.filter(
      (team) => team.NextTeamID !== '',
    );
    return (
      <section
        className={`table w-full table-regular ${
          this.props.hasGradient ? 'has-gradient' : ''
        } position-${this.props.activePosition}`}
      >
        {this.props.caption ? (
          <caption className='table-caption-section py-17 bg-widget-grey relative'>
            <div className='flex flex-row justify-center items-center'>
              <h6 className='table-caption-text font-MarkProHeavy text-base text-white leading-none'>
                {this.props.caption}
              </h6>
              {this.props.fullTableLink ? (
                <a
                  href={this.props.fullTableLink}
                  className='font-MarkPro text-xs text-white leading-loose absolute pin-r top-0 mt-14 mr-14 cursor-pointer hover:text-teal os-transition-btn'
                >
                  Full Table
                </a>
              ) : null}
            </div>
          </caption>
        ) : null}
        {this.props.hasGradient ? <div className='top-gradient'></div> : null}
        <TableHeader
          headers={this.props.headers}
          sectionFixtures={this.props.fixtures}
          collapsedData={this.props.collapsedData}
          currentURL={this.props.currentURL}
          teamsWithNextMatch={teamsWithNextMatch}
        />

        <div className='table-body'>
          {this.renderRows(
            this.props.data,
            onRowClick,
            this.props.teams,
            this.props.isGroupTable,
          )}
        </div>
        {this.props.hasGradient ? (
          <div className='bottom-gradient'></div>
        ) : null}
      </section>
    );
  }
}
RegularTable.defaultProps = {
  onRowClick: () => {},
  isGroupTable: false,
  type: 'competitionTable',
};
export default RegularTable;
