import { API } from 'aws-amplify';
import { get } from 'lodash';

import { API_AUTH } from '../constants/apis';

export const GENERATE_CODE_START = `device/GENERATE_CODE/START`;
export const GENERATE_CODE_SUCCESS = `device/GENERATE_CODE/SUCCESS`;
export const GENERATE_CODE_FAILURE = `device/GENERATE_CODE/FAILURE`;

export const DEVICE_RESET = `device/DEVICE_RESET/START`;
export const POLL_RESET = `device/POLL_RESET/START`;

export const POLL_DEVICE_START = `device/POLL_DEVICE/START`;
export const POLL_DEVICE_SUCCESS = `device/POLL_DEVICE/SUCCESS`;
export const POLL_DEVICE_FAILURE = `device/POLL_DEVICE/FAILURE`;

export const STATUS_DEVICE_START = `device/STATUS_DEVICE/START`;
export const STATUS_DEVICE_SUCCESS = `device/STATUS_DEVICE/SUCCESS`;
export const STATUS_DEVICE_FAILURE = `device/STATUS_DEVICE/FAILURE`;

export const VALIDATE_DEVICE_START = `device/VALIDATE_DEVICE/START`;
export const VALIDATE_DEVICE_SUCCESS = `device/VALIDATE_DEVICE/SUCCESS`;
export const VALIDATE_DEVICE_FAILURE = `device/VALIDATE_DEVICE/FAILURE`;

// TEST USE ONLY
const generatePairDeviceCode = ({ platform = 'web' }) => {
  return (dispatch) => {
    const endpoint = API_AUTH;
    const path = `/devicelogin/${platform}/initiate`;

    dispatch({
      type: GENERATE_CODE_START,
    });

    const params = {
      headers: {},
      body: {
        deviceId: 'xyz123xyz',
        deviceName: 'deviceName',
        vendor: 'atv',
        model: 'xyz1234',
        osName: 'sony',
        osVersion: '1.11',
      },
    };

    return API.post(endpoint, path, params)
      .then((response) => {
        const { data } = response;
        dispatch({
          type: GENERATE_CODE_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        const error = get(err, ['code'], err);
        dispatch({
          type: GENERATE_CODE_FAILURE,
          data: error,
        });
      });
  };
};

const getErrorCode = ({ code = '' }) =>
  ({
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    SERVER_ERROR: 'SERVER_ERROR',
    INVALID_USER_CODE: 'INVALID_USER_CODE',
    PAIRED_USER_CODE: 'PAIRED_USER_CODE',
    GENERAL: 'GENERAL',
  }[code] || 'GENERAL');

const validatePairDevice = ({ userId, userCode = '', platform = 'web' }) => {
  return (dispatch) => {
    const params = {
      headers: {},
      body: { userCode },
    };
    if (!userId) {
      dispatch({
        type: VALIDATE_DEVICE_FAILURE,
        data: getErrorCode({ code: 'GENERAL' }),
      });
      return;
    }

    dispatch({ type: VALIDATE_DEVICE_START });

    const endpoint = API_AUTH;
    const path = `/devicelogin/${platform}/validate`;

    API.post(endpoint, path, params)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          dispatch({
            type: VALIDATE_DEVICE_SUCCESS,
            data,
          });
        } else {
          dispatch({
            type: VALIDATE_DEVICE_FAILURE,
            data: getErrorCode({ code: response?.error?.code }),
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: VALIDATE_DEVICE_FAILURE,
          data: getErrorCode({ code: error?.code }),
        });
      });
  };
};

const resetPairDevice = () => async (dispatch) => {
  dispatch({
    type: DEVICE_RESET,
  });
};

const resetPoll = () => async (dispatch) => {
  dispatch({
    type: POLL_RESET,
  });
};

const statusPairDevice = ({ deviceCode = '', platform = 'web' }) => {
  return (dispatch) => {
    const endpoint = API_AUTH;
    const path = `/devicelogin/${platform}/status/${deviceCode}`;

    const params = {
      headers: {},
      body: { deviceCode },
    };

    dispatch({ type: STATUS_DEVICE_START, pollLoading: true });

    API.get(endpoint, path, params)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          dispatch({
            type: STATUS_DEVICE_SUCCESS,
            data,
          });
        } else {
          dispatch({
            type: STATUS_DEVICE_FAILURE,
            data: getErrorCode({ code: response?.error?.code }),
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: STATUS_DEVICE_FAILURE,
          data: getErrorCode({ code: error?.code }),
        });
      });
  };
};

// TESTING ONLY
const pollPairDevice = ({ platform = 'web', deviceCode = '' }) => {
  return (dispatch) => {
    const endpoint = API_AUTH;
    const path = `/devicelogin/${platform}/poll`;
    const params = {
      headers: {},
      body: { deviceCode },
    };
    dispatch({ type: POLL_DEVICE_START });
    API.post(endpoint, path, params)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          dispatch({
            type: POLL_DEVICE_SUCCESS,
            data,
          });
        } else {
          dispatch({
            type: POLL_DEVICE_FAILURE,
            data: getErrorCode({ code: response?.error?.code }),
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: POLL_DEVICE_FAILURE,
          data: getErrorCode({ code: error?.code }),
        });
      });
  };
};

const initialState = {
  validateLoading: false,
  validateData: null,
  validateError: null,
  statusLoading: false,
  statusData: null,
  statusError: null,
  // TESTING ONLY:
  pollLoading: false,
  pollData: null,
  pollError: null,
  // TESTING ONLY:
  codeLoading: false,
  codeData: null,
  codeError: null,
};

const deviceReducer = (oldState = initialState, action) => {
  const state = JSON.parse(JSON.stringify(oldState));
  const { data, type } = action;
  switch (type) {
    case VALIDATE_DEVICE_START: {
      return {
        ...state,
        validateLoading: true,
        validateData: null,
        validateError: null,
      };
    }
    case VALIDATE_DEVICE_SUCCESS: {
      return {
        ...state,
        validateLoading: false,
        validateData: data,
        validateError: null,
      };
    }
    case VALIDATE_DEVICE_FAILURE: {
      return {
        ...state,
        validateLoading: false,
        validateData: null,
        validateError: data,
      };
    }
    case GENERATE_CODE_START: {
      return {
        ...state,
        codeLoading: true,
        codeError: null,
        codeData: null,
      };
    }
    case GENERATE_CODE_SUCCESS: {
      return {
        ...state,
        codeLoading: false,
        codeData: data,
        codeError: null,
      };
    }
    case GENERATE_CODE_FAILURE: {
      return {
        ...state,
        codeLoading: false,
        codeData: null,
        codeError: data,
      };
    }
    // START HERE
    case POLL_DEVICE_START: {
      return {
        ...state,
        pollLoading: true,
        pollError: null,
        pollData: null,
      };
    }
    case POLL_DEVICE_SUCCESS: {
      return {
        ...state,
        pollLoading: false,
        pollData: data,
        pollError: null,
      };
    }
    case POLL_DEVICE_FAILURE: {
      return {
        ...state,
        pollLoading: false,
        pollData: null,
        pollError: data,
      };
    }
    case STATUS_DEVICE_START: {
      return {
        ...state,
        statusLoading: true,
        statusError: null,
        statusData: null,
      };
    }
    case STATUS_DEVICE_SUCCESS: {
      return {
        ...state,
        statusLoading: false,
        statusData: data,
        statusError: null,
      };
    }
    case STATUS_DEVICE_FAILURE: {
      return {
        ...state,
        statusLoading: false,
        statusData: null,
        statusError: data,
      };
    }
    case POLL_RESET: {
      return {
        // TESTING ONLY:
        codeLoading: false,
        codeData: null,
        codeError: null,
        // TESTING ONLY:
        pollLoading: false,
        pollData: null,
        pollError: null,
      };
    }
    /// end here
    case DEVICE_RESET: {
      return {
        validateLoading: false,
        validateData: null,
        validateError: null,
        statusLoading: false,
        statusData: null,
        statusError: null,
        // TESTING ONLY:
        codeLoading: false,
        codeData: null,
        codeError: null,
        // TESTING ONLY:
        pollLoading: false,
        pollData: null,
        pollError: null,
      };
    }
    default:
      return state;
  }
};

export {
  generatePairDeviceCode,
  statusPairDevice,
  pollPairDevice,
  resetPoll,
  validatePairDevice,
  resetPairDevice,
};

export default deviceReducer;
