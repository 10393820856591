import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconSmallRight from './Icons/IconSmallRight';
import Switch from './Switch';

const menu = 'menu';
const subMenu = 'subMenu';
const toggleMenu = 'toggleMenu';

const bgNavLink = (active) =>
  active ? 'border-teal' : 'hover:border-teal border-transparent';

const NavLink = ({
  label,
  type,
  active,
  onToggleChange,
  className,
  onClick,
  link,
  ...props
}) => (
  <div className='border-b border-solid border-medium-grey m-0 p-0 block w-full'>
    <li
      className={`flex flex-no-wrap items-center os-transition w-full
      cursor-pointer bg-widget-grey h-48 pr-8 pl-16
      list-reset border-l-3 leading-base ${bgNavLink(active)}  ${
        className ? ` ${className}` : className
      }`}
    >
      <Link
        className='text-white hover:text-teal text-sm flex-grow no-underline'
        to={link}
      >
        {label}
      </Link>

      {type === subMenu && (
        <Link className='text-white hover:text-teal no-underline' to={link}>
          {' '}
          <IconSmallRight />{' '}
        </Link>
      )}
      {type === toggleMenu && (
        <Switch
          className='mr-8'
          changed={(state) => {
            onToggleChange && onToggleChange(state);
          }}
        />
      )}
    </li>
  </div>
);

NavLink.propTypes = {
  /** Label for the nav link*/
  label: PropTypes.string,
  /** Whether this is a sub menu or not*/
  subMenu: PropTypes.bool,
  /** Whether this link is active or not*/
  active: PropTypes.bool,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Type of this link*/
  type: PropTypes.oneOf([menu, subMenu, toggleMenu]),
  /** Handler function for this link. This function will be invoked with the click event received on the link item */
  onToggleChange: PropTypes.func,
  /** Click handler for this link.*/
  onClick: PropTypes.func,
  /** URL to this link.*/
  link: PropTypes.string,
};

NavLink.defaultProps = {
  label: null,
  subMenu: false,
  active: false,
  className: null,
  type: menu,
  onToggleChange: null,
  onClick: null,
};

export default NavLink;
