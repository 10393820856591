import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import get from 'lodash/get';
import HeaderNav from 'components/ui/ui-kit/HeaderNav/';
import { setDocumentTitle } from 'actions/documentTitle';
import { selectors, useSelector } from 'store/createPortalStore';
import { getAbsoluteStaticUrl } from 'utils/path';
import Button from 'components/ui/ui-kit/Button';
import VendorButton from 'components/ui/ui-kit/VendorButton';
import { getDevice } from 'utils';
import PageFooter from 'components/features/PageFooter';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import { RailCarousel } from 'components/ui/new-home/layouts/LayoutCRailCarousel';
import { loadEditorial } from 'actions/editorial';
import { initializeGoogleAnalytics, triggerGoogleAnalyticsEvent } from 'utils';
// import ImportantInformation from 'components/ui/ImportantInformation';

const MOAModal = loadable(() => import('components/ui/MOAModal'));

const FitnessLanding = ({ getFeatureFlags }) => {
  const isLoggedIn = useSelector(selectors.user.isLoggedIn);

  const fitnessLandingConfig = getFeatureFlags('FITNESS_LANDING_CONFIG');
  const carouselConfig = get(fitnessLandingConfig, 'carousel', {});

  const [showMOAModal, setShowMOAModal] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDocumentTitle('Fitness on Optus Sport'));
    dispatch(loadEditorial('top_picks', 16));
    initializeGoogleAnalytics();
    // eslint-disable-next-line
  }, []);

  const userDevice = getDevice();
  const deviceType = get(userDevice, 'type', 'PC');
  const downloadAppUrl =
    deviceType === 'PC'
      ? 'https://itunes.apple.com/au/app/optus-sport/id1113368382?mt=8'
      : 'https://c5pq6.app.goo.gl/?link=https://sport.optus.com.au&apn=au.com.optus.sport&isi=1113368382&ibi=au.com.optus.epl&ius=optussport';
  const downloadPlayStoreUrl =
    deviceType === 'PC'
      ? 'http://play.google.com/store/apps/details?id=au.com.optus.sport'
      : 'https://c5pq6.app.goo.gl/?link=https://sport.optus.com.au&apn=au.com.optus.sport&isi=1113368382&ibi=au.com.optus.epl&ius=optussport';

  return (
    <div className='fitness-landing'>
      <HeaderNav
        customClassNames='nav'
        simpleHeader={true}
        showBack={true}
        onBackClicked={() => {
          triggerGoogleAnalyticsEvent(
            'Fitness-Learn More',
            'click',
            'Home Button',
          );
          history.push('/fitness');
        }}
        backLabel='Home'
        textClass='text-black'
      >
        {!isLoggedIn && (
          <button
            className='text-black focus:outline-none mr-20'
            onClick={(e) => {
              triggerGoogleAnalyticsEvent(
                'Fitness-Learn More',
                'click',
                'Log in Button',
              );
              history.push('/login', {
                redirectTo: '/fitness',
                referrer: location,
              });
            }}
          >
            <span className='text-sm font-MarkProHeavy leading-2.29'>
              Login
            </span>
          </button>
        )}
      </HeaderNav>
      <section className='fitness-hero w-full relative flex justify-center pb-64 md:pb-80 lg:pb-0 lg:min-h-640 overflow-hidden'>
        <img
          className='absolute z-10 object-cover hidden xl:inline-block h-full xxxl:h-auto w-full'
          src={getAbsoluteStaticUrl('/images/fitness-landing-hero.jpg')}
          alt='Fitness landing hero'
        />
        <img
          className='absolute z-10 object-cover hidden md:inline-block xl:hidden h-full w-full'
          src={getAbsoluteStaticUrl('/images/fitness-landing-hero-tablet.jpg')}
          alt='Fitness landing hero'
        />
        <img
          className='absolute z-10 object-cover inline-block h-full w-full sm:h-auto md:hidden'
          src={getAbsoluteStaticUrl('/images/fitness-landing-hero-mobile.jpg')}
          alt='Fitness landing hero'
        />
        <div className='z-100 w-full max-w-1184 flex items-start lg:items-center'>
          <div className='px-16 md:pl-32 lg:pl-96 xxl:pl-0 text-left pt-216 md:pt-104 lg:pt-60'>
            <span className='optus-sport-yes block mb-32 md:mb-24 lg:mb-56 lg:ml-0 w-201'>
              <img
                className='w-full h-auto'
                src={getAbsoluteStaticUrl('/images/optus-sport-yes.svg')}
                alt='Optus sport logo'
              />
            </span>
            <h1 className='font-MarkProHeavy font-900 text-black text-3xl sm:text-4xl lg:text-6xl leading-1_25'>
              Workout your way
            </h1>
            <p className='font-MarkPro text-black text-base lg:text-lg leading-normal lg:leading-extra mr-auto mt-16 md:mt-8 mb-32 md:mb-24 lg:mb-56 md:max-w-533 lg:max-w-830 xxxl:max-w-896  xxxl:mt-24 '>
              <span>
                Free workouts, recipes, recovery and meditation from leading
                trainers, nutritionists and experts.{' '}
                <span className='font-MarkProBold'>
                  Sign up for a free Optus Sport account to get Fitness
                </span>
              </span>
            </p>
            <div className='flex flex-col justify-start items-center sm:items-start sm:flex-row w-full'>
              <Button
                handler={() => {
                  triggerGoogleAnalyticsEvent(
                    'Fitness-Learn More',
                    'click',
                    `${isLoggedIn ? 'Browse workouts' : 'Sign Up Free'}`,
                  );
                  history.push(isLoggedIn ? '/fitness' : '/register');
                }}
                text={isLoggedIn ? 'Browse workouts' : 'Sign Up Free'}
                width='w-full sm:w-256 lg:w-305 text-base xxxl:h-48'
                type='primary'
                className='w-full sm:w-auto'
                theme='light'
              ></Button>
              {!isLoggedIn && (
                <Button
                  handler={(e) => {
                    e.preventDefault();
                    triggerGoogleAnalyticsEvent(
                      'Fitness-Learn More',
                      'click',
                      'Log in Button',
                    );
                    history.push('/login', {
                      redirectTo: '/fitness',
                      referrer: location,
                    });
                  }}
                  type='secondary-alternate'
                  theme='light'
                  text='Log in'
                  rounded={true}
                  width='w-full sm:w-256 lg:w-305 text-base xxxl:h-48'
                  className='w-full ml-0 mt-16 sm:ml-24 sm:mt-0'
                ></Button>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className='relative w-full flex flex-col bg-grey'>
        <div className='flex flex-col bg-white'>
          <div className='flex flex-col bg-grey xxl:items-center'>
            <div className='flex flex-col bg-grey xs:flex-row w-full text-black px-16 md:px-32 lg:pl-96 xxl:pl-56 xxxl:pl-40 pt-16 sm:pt-32 md:pt-64 xl:pt-96 xl:pb-48 max-w-1280'>
              <div className='w-full xs:flex-none sm:h-544 md:h-auto'>
                <div className='sm:w-1/2 md:w-381 xl:w-1/2 xxl:w-3/7'>
                  <h1 className='font-MarkProHeavy font-900 text-black leading-less text-2xl lg:text-4xl'>
                    Fitness for everybody
                  </h1>
                  <div className='pt-20 leading-normal'>
                    With a wide variety of workouts we've got something to suit
                    every level, goal, mood, time or environment
                  </div>
                </div>
                <div className='py-44 font-MarkProHeavy font-900'>
                  <div>
                    <div className='text-cyan-blue text-4xl sm:text-6xl lg:text-7xl'>
                      1000+
                    </div>
                    <div className='pb-24 md:pt-16 md:pb-40'>
                      workouts on demand
                    </div>
                  </div>
                  <div>
                    <div className='text-cyan-blue text-4xl sm:text-6xl lg:text-7xl'>
                      5 - 45
                    </div>
                    <div className='pb-24 md:pt-16 md:pb-40'>
                      minute workouts
                    </div>
                  </div>
                  <div>
                    <div className='text-cyan-blue text-4xl sm:text-6xl lg:text-7xl'>
                      99+
                    </div>
                    <div className='xs:pb-24 md:pt-16 md:pb-40'>
                      expert trainers
                    </div>
                  </div>
                </div>
              </div>
              <picture className='mobiles-img'>
                <source
                  media={`(max-width:375px)`}
                  srcSet={getAbsoluteStaticUrl('/images/fitness-phones@1x.png')}
                />
                <source
                  media={`(max-width:1200px)`}
                  srcSet={getAbsoluteStaticUrl('/images/fitness-phones@2x.png')}
                />
                <img
                  src={getAbsoluteStaticUrl('/images/fitness-phones@3x.png')}
                  alt={''}
                  className={'h-full w-full object-cover'}
                />
              </picture>
            </div>
          </div>
          <div className='h-80 w-full'></div>
        </div>
        <div className='flex flex-col items-center bg-white text-black px-16 pb-60 lg:pt-96 xl:pb-112'>
          <h1 className='font-MarkProHeavy text-center mb-16 md:mb-32 lg:text-4xl'>
            Over 20 styles for any level
          </h1>
          <div className='text-center mb-32 sm:mb-40 md:64 md:max-w-581 xl:max-w-830 leading-normal'>
            Optus Sport makes it easy to find the perfect workout with anything
            from yoga to HIIT. Workout in your living room, at the gym,
            on-the-go, on any device
          </div>
          <div className='styles-block flex flex-wrap items-center justify-evenly md:max-w-704 xl:max-w-942'>
            <div className='pills md:ml-16 xl:ml-64'>
              <img alt='' src={getAbsoluteStaticUrl('/images/icon-yoga.svg')} />
              <span className='text'>Yoga</span>
            </div>
            <div className='pills'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-cardio.svg')}
              />
              <span className='text'>Cardio</span>
            </div>
            <div className='pills'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-resistance.svg')}
              />
              <span className='text'>Resistance</span>
            </div>
            <div className='pills md:mr-16 xl:mr-0'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-pilates.svg')}
              />
              <span className='text'>Pilates</span>
            </div>
            <div className='pills xl:mr-64'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-recovery.svg')}
              />
              <span className='text'>Stretch</span>
            </div>
            <div className='pills xl:ml-192'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-sport-skills.svg')}
              />
              <span className='text'>Skills</span>
            </div>
            <div className='pills'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-meditation.svg')}
              />
              <span className='text'>Wellbeing</span>
            </div>
            <div className='pills xl:mr-192'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-pregnancy.svg')}
              />
              <span className='text'>Pregnancy</span>
            </div>
          </div>
        </div>
        <div className='rail flex flex-col bg-grey text-black pl-16 pt-16 sm:pl-32 xxxl:items-center'>
          <div className='max-w-1216'>
            <RailCarousel
              key={carouselConfig.id}
              carouselId={carouselConfig.id}
              title={carouselConfig.title}
              sectionPath={carouselConfig.sectionPath}
              showViewAllLink={false}
              theme='light'
              target='_blank'
              fitnessLanding={true}
            />
          </div>
          <Button
            handler={() => {
              triggerGoogleAnalyticsEvent(
                'Fitness-Learn More',
                'click',
                'Full Range Button',
              );
              history.push('/fitness');
            }}
            type='secondary-alternate'
            theme='light'
            text='See the full range'
            rounded={true}
            buttonClassName='w-full sm:w-256 md:w-276 lg:w-305 lg:text-base'
            className='w-full self-center sm:w-auto ml-0 mt-24 pb-64 mr-16 sm:mr-32'
          ></Button>
        </div>
        <div className='flex flex-col bg-white text-black p-16 items-center'>
          <h1 className='font-MarkProHeavy mt-48 lg:mt-80 mb-16 md:mb-32 lg:text-4xl text-center'>
            Friendly trainers and experts
          </h1>
          <div className='leading-normal text-center md:max-w-581 lg:max-w-860'>
            We've partnered with leading local and global fitness trainers,
            chosen for their expertise, to help you get moving and stay
            motivated
          </div>
          <div className='flex flex-col xs:flex-row flex-wrap justify-center items-center space-evenly pb-60 lg:pb-112 xs:max-w-358 md:max-w-581 lg:max-w-753'>
            <img
              className='h-32 lg:h-40 mt-32'
              alt=''
              src={getAbsoluteStaticUrl('/images/icon-fiit.png')}
            ></img>
            <img
              className='h-28 lg:h-36 xs:ml-32 md:ml-40 mt-38px'
              alt=''
              src={getAbsoluteStaticUrl('/images/lean-bean-logo-1.png')}
            ></img>
            <img
              className='h-32 lg:h-40 mt-32 md:ml-40'
              alt=''
              src={getAbsoluteStaticUrl('/images/icon-flow-athletic.png')}
            ></img>
          </div>
        </div>
        {/*
          * REMOVED FOR OSN-1664
          * KEEP THE CODES IN PLACE FOR FUTURE USE
        !isLoggedIn && (
          <>
            <div className='flex flex-col bg-grey text-black text-center items-center justify-center px-32 lg:pl-96 py-56 lg:py-120'>
              <h1 className='font-MarkProHeavy lg:text-4xl'>Ready to move?</h1>
              <div className='py-16 leading-normal max-w-860 text-base'>
                If you're an eligible Optus customer, start working out at no
                extra cost* by activating your Optus Sport subscription in My
                Optus app. Simply follow the prompts to verify your email, log
                in to Optus Sport and start your fitness journey.
              </div>
              <div className='flex flex-col md:flex-row-reverse max-w-1280'>
                <div className='md:w-1/2'>
                  <picture>
                    <source
                      media={`(max-width:375px)`}
                      srcSet={getAbsoluteStaticUrl('/images/MOA-steps@1x.png')}
                    />
                    <source
                      media={`(max-width:768px)`}
                      srcSet={getAbsoluteStaticUrl('/images/MOA-steps@2x.png')}
                    />
                    <img
                      src={getAbsoluteStaticUrl('/images/MOA-steps@3x.png')}
                      alt={''}
                    />
                  </picture>
                </div>
                <div className='text-left pt-32 xl:pt-56 md:w-1/2'>
                  <h2 className='font-MarkProHeavy pb-8 leading-loose text-xl xxl:text-2xl'>
                    1. Activate using My Optus App
                  </h2>
                  <div className='leading-normal'>
                    Activate your Optus Sport subscription in My Optus App
                  </div>
                  <h2 className='font-MarkProHeavy pt-32 pb-8 leading-loose text-xl xxl:text-2xl'>
                    2. Verify email
                  </h2>
                  <div className='leading-normal'>
                    Verify your email address that you chose to create your
                    Optus Sport account
                  </div>
                  <h2 className='font-MarkProHeavy pt-32 pb-8 leading-loose text-xl xxl:text-2xl'>
                    3. Log in and start working out
                  </h2>
                  <div className='leading-normal'>
                    Open or download the Optus Sport app, log in and start
                    working out
                  </div>
                  <Button
                    handler={() => {
                      triggerGoogleAnalyticsEvent(
                        'Fitness-Learn More',
                        'click',
                        'Join now button',
                      );
                      setShowMOAModal(true);
                    }}
                    type='secondary-alternate'
                    theme='light'
                    text='Join now'
                    rounded={true}
                    width='w-full sm:w-256 md:w-276 lg:text-base'
                    className='w-full self-center sm:w-auto ml-0 mt-32 md:mt-56 lg:mt-80'
                  ></Button>
                </div>
              </div>
            </div>
            <div className='flex flex-col text-center justify-center bg-grey px-16 py-64 lg:pt-0 lg:pb-96 text-black'>
              <h1 className='font-MarkProHeavy mb-16 md:mb-32 lg:text-4xl text-center'>
                Not an Optus customer?
              </h1>
              <div className='leading-normal'>
                Purchase a subscription today for $24.99/mth to enjoy exclusive
                access to premium fitness and the world's best football!
              </div>
              <Button
                handler={() => {
                  triggerGoogleAnalyticsEvent(
                    'Fitness-Learn More',
                    'click',
                    'Purchase Subscription Button',
                  );
                  history.push('/register', {
                    redirectTo: '/fitness',
                    referrer: location,
                  });
                }}
                type='primary'
                theme='light'
                text='Purchase subscription'
                rounded={true}
                width='w-full sm:w-256 md:w-276 lg:text-base'
                className='w-full self-center sm:w-auto ml-0 mt-32 md:mt-48'
              ></Button>
            </div>
          </>
              )*/}
        <div className='flex flex-col bg-grey text-center justify-center items-center px-16 py-32 lg:p-96 text-black'>
          <h1 className='font-MarkProHeavy lg:text-4xl text-center'>
            What else we offer...
          </h1>
          <div className='flex flex-col text-center justify-center sm:max-w-581 md:flex-row md:max-w-full xxl:max-w-1200'>
            <div className='md:w-1/3'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-devices.svg')}
                className='pt-32 md:pt-40'
              />
              <h2 className='font-MarkProHeavy py-8 md:text-base lg:text-lg xl:text-xl'>
                One account, on many devices
              </h2>
              <p className='leading-normal'>
                Stream Fitness on Optus Sport on your mobile, tablet, laptop or
                Smart TV at home.
              </p>
            </div>
            <div className='md:w-1/3 md:ml-16 lg:ml-32'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-settings.svg')}
                className='pt-32 md:pt-40'
              />
              <h2 className='font-MarkProHeavy py-8 md:text-base lg:text-lg xl:text-xl'>
                Find your perfect workout
              </h2>
              <p className='leading-normal'>
                Filter through a huge selection of fitness classes to find a
                workout that suits you.
              </p>
            </div>
            <div className='md:w-1/3 md:ml-16 lg:ml-32'>
              <img
                alt=''
                src={getAbsoluteStaticUrl('/images/icon-heart.svg')}
                className='pt-32 md:pt-40'
              />
              <h2 className='font-MarkProHeavy py-8 md:text-base lg:text-lg xl:text-xl'>
                Favourite workouts -{' '}
                <span className='text-green-dark'>Coming soon</span>
              </h2>
              <p className='leading-normal'>
                Found your perfect workout? Favouriting makes it easier than
                ever to make sure you always know where to find it.
              </p>
            </div>
          </div>
          <div className='flex flex-col text-center items-center w-full mt-48 lg:mt-64'>
            <h2 className='font-MarkProHeavy py-8 text-base lg:text-lg xl:text-xl'>
              Download and sign up in the app directly.
            </h2>
            <div className='flex flex-col sm:flex-row flex-no-wrap w-full justify-center justify-evenly my-16 sm:max-w-570'>
              <VendorButton
                link={downloadAppUrl}
                linkTarget='_blank'
                className='rounded-full mb-16 sm:w-240'
                type='apple'
                theme='light'
                handler={() =>
                  triggerGoogleAnalyticsEvent(
                    'Fitness-Learn More',
                    'click',
                    'iOS App Button',
                  )
                }
              />

              <VendorButton
                link={downloadPlayStoreUrl}
                linkTarget='_blank'
                className='rounded-full mb-16 sm:w-240'
                theme='light'
                handler={() =>
                  triggerGoogleAnalyticsEvent(
                    'Fitness-Learn More',
                    'click',
                    'Android Button',
                  )
                }
              />
            </div>
            <div className='text-black text-xs mb-56 leading-normal lg:max-w-830'>
              <p>
                Google Play and the Google Play logo are trademarks of Google
                LLC. Apple and the Apple logo are trademarks of Apple Inc. App
                Store is a service mark of Apple Inc.
              </p>
            </div>
            <div className='flex flex-col w-full lg:max-w-830'>
              <div className='flex flex-col xs:flex-row items-center justify-between font-MarkProBold mx-16 lg:mx-0 border-t border-b border-medium-grey pt-8'>
                <span className='mb-16 xs:mb-0'>Check out our socials</span>
                <span>
                  <a
                    href='https://www.facebook.com/optussportfitness/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      alt=''
                      src={getAbsoluteStaticUrl(
                        '/images/icon-facebook-black.svg',
                      )}
                      className='mr-8 sm:mr-16 lg:mr-24'
                    />
                  </a>
                  <a
                    href='https://www.instagram.com/optussportfitness/?hl=en'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      alt=''
                      src={getAbsoluteStaticUrl(
                        '/images/icon-instagram-black.svg',
                      )}
                    />
                  </a>
                </span>
              </div>
              {/*<ImportantInformation*/}
              {/*title='Important information'*/}
              {/*theme='light'*/}
              {/*className='mt-8'*/}
              {/*/>*/}
            </div>
          </div>
        </div>
        <div className='flex flex-col items-start xxl:items-center justify-center bg-white h-168 sm:h-176 md:h-192 lg:h-279 pl-16 lg:pl-96 md:pl-32 banner'>
          <div className='xxl:w-1264'>
            <img
              alt=''
              src={getAbsoluteStaticUrl('/images/optus-sport-yes.svg')}
              className='h-24 lg:h-28'
            />
            <p className='font-MarkProHeavy mt-16 lg:mt-28 text-2xl lg:text-4xl'>
              Unparalleled for Premier League
            </p>
          </div>
        </div>
        <div className='flex items-center justify-center bg-core-dark-grey h-112 sm:h-176 md:h-192 lg:h-279'>
          <span className='font-MarkProHeavy text-white text-base sm:text-2xl lg:text-4xl pr-16 sm:pr-24'>
            Get fitter. It starts with{' '}
          </span>
          <img
            alt=''
            src={getAbsoluteStaticUrl('/images/optus-yes-logo.png')}
            className='w-72 sm:w-120 lg:w-170'
          />
        </div>
      </section>
      {showMOAModal && (
        <MOAModal
          open={showMOAModal}
          dismissOnOutsideClick={false}
          onClose={() => {
            setShowMOAModal(false);
          }}
        />
      )}
      <PageFooter hideDownloadApps={true} />
    </div>
  );
};

export default withFeatureFlags(FitnessLanding);
