import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const typeTime = 'time';
const typeLive = 'live';

const commonTextStyle = () =>
  `font-MarkProBold text-2xs sm:p-10 sm:bg-medium-dark-grey
   lg:text-base lg:py-0 lg:w-100 leading-base lg:leading-double text-center`;

const filteredMatchStatus = (matchStatus) => {
  switch (matchStatus) {
    case 'FULL':
      return 'FT';
    case 'HALF-TIME':
      return 'HT';
    case 'AET':
      return 'ET';
    case 'P':
      return 'PK';
    default:
      return matchStatus;
  }
};

const renderLiveStatus = (livematch, label) => {
  if (!_.isEmpty(livematch)) {
    const matchMinutes = parseInt(
      Math.floor(livematch.liveTimeElapsedSeconds / 60),
    );
    const matchStatus = filteredMatchStatus(livematch.MatchStatus);
    const displayText =
      matchStatus === 'LIVE' ? `LIVE ${matchMinutes}'` : matchStatus;
    return (
      <div
        className={`flex flex-row flex-no-wrap items-center justify-center ${commonTextStyle()} ${
          matchStatus === 'LIVE' ? 'text-live-red' : 'text-white'
        } h-20`}
      >
        <div className='w-6 h-6 bg-live-red rounded-full mr-3' />
        <span
          className={`${
            label !== null
              ? `hidden lg:inline-flex flex-no-wrap`
              : 'md:py-16 inline-flex flex-no-wrap'
          } text-2xs md:py-0 font-MarkProBold ml-2 `}
        >
          {displayText}
        </span>
        {label && (
          <span className='ml-2 text-2xs leading-base font-MarkProBold '>
            {label}
          </span>
        )}
      </div>
    );
  }
  return null;
};

const FixtureStatus = ({ label, type, className, mini, liveMatch }) => (
  <div className={`flex ${className !== null ? ` ${className}` : ''}`}>
    {type === typeTime ? (
      <div
        className={`flex items-center justify-center ${
          mini ? 'mini h-20 w-46 justify-center' : ''
        }`}
      >
        <p
          className={`${
            !mini
              ? commonTextStyle()
              : 'text-center h-20 w-46 align-middle font-MarkProBold text-2xs sm:bg-medium-dark-grey pt-3 leading-1.6'
          } text-white`}
        >
          {label}
        </p>
      </div>
    ) : (
      renderLiveStatus(liveMatch, label)
    )}
  </div>
);

FixtureStatus.propTypes = {
  /** Label for this status item */
  label: PropTypes.string,
  /** Type of status item */
  type: PropTypes.oneOf([typeTime, typeLive]),
  /** To render a compact version . */
  mini: PropTypes.bool,
};

FixtureStatus.defaultProps = {
  label: null,
  type: typeLive,
  mini: false,
};

export default FixtureStatus;
