import { combineReducers } from 'redux';
import systemConfig from './systemConfigReducer';
import navigation from './navigationReducer';
import pages from './pagesReducer';
import content from './contentReducer';
import editorials from './editorialsReducer';
import fixtures from './fixturesReducer';
import results from './resultsReducer';
import tables from './tablesReducer';
import teams from './teamsReducer';
import teamcolors from './teamColors';
import user from './userReducer';
import bannerAsset from './bannerAssetReducer';
import asset from './assetReducer';
import stats from './statsReducer';
import commentary from './commentaryReducer';
import highlights from './highlightsReducer';
import lineup from './lineupReducer';
import squad from './squadReducer';
import teamStats from './teamStatsReducer';
import teamSummary from './teamSummaryReducer';
import ticketManager from './ticketManagerReducer';
import allteams from './allTeamsReducer';
import allresults from './allResultsReducer';
import allfixtures from './allFixturesReducer';
import matchhighlights from './matchHighlightsReducer';
import comparison from './teamComparison';
import teamPreview from './teamPreviewReducer';
import livescore from './livescoreReducer';
import contentCards, {
  contentCardsUnfiltered,
  dynamicContentCards,
} from './contentCardsReducer';
import { loadingBarReducer } from 'react-redux-loading-bar';
import assetCategory from './assetCategoryReducer';
// import { reducer as notifications } from 'react-notification-system-redux';
import livematches from './livematchesReducer';
import saagdata from './saagdataReducer';
import saagStatus from './saagStatusReducer';
import documentTitle from './documentTitleReducer';
import metaDescription from './metaDescriptionReducer';
import themeConfig from './themeReducer';
import relatedAssets from '../ducks/relatedAssets';
import verification from '../ducks/verification';
import device from '../ducks/device';
import voucher from '../ducks/voucher';
import account from '../ducks/user';
import stepper from '../ducks/stepper';
import signup from '../ducks/signup';
import subscription from '../ducks/subscription';
import retention from '../ducks/retention';
import userCompetition from './userCompetitionReducer';
import deviceManagement from '../ducks/deviceManagement';
import payment from '../ducks/payment';
import coupon from '../ducks/coupon';
import loading from '../ducks/loading';
import analytic from '../ducks/analytic';

const rootReducer = combineReducers({
  analytic,
  systemConfig,
  userCompetition,
  navigation,
  pages,
  editorials,
  fixtures,
  results,
  device,
  tables,
  coupon,
  teams,
  teamcolors,
  user,
  bannerAsset,
  asset,
  ticketManager,
  stats,
  commentary,
  highlights,
  relatedAssets,
  lineup,
  squad,
  teamStats,
  teamSummary,
  allteams,
  allresults,
  allfixtures,
  matchhighlights,
  comparison,
  teamPreview,
  livescore,
  loadingBar: loadingBarReducer,
  assetCategory,
  // notifications,
  livematches,
  saagdata,
  saagStatus,
  documentTitle,
  metaDescription,
  themeConfig,
  verification,
  voucher,
  account,
  stepper,
  signup,
  subscription,
  retention,
  deviceManagement,
  payment,
  loading,
  content,
  contentCards,
  contentCardsUnfiltered,
  dynamicContentCards,
});

export default rootReducer;
