import React, { Component } from 'react';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';

import mobileDetect from '../../utils/mobileDetect';
import BackIcon from '../../components/ui/ui-kit/Icons/BackIcon';

smoothscroll.polyfill();

class Carousel extends Component {
  initialResize = false;
  currentScrollWidth = 0;

  constructor(props) {
    super(props);

    this.state = {
      showSlider: false,
      selectedTabIndex: 0,
      count: 0,
      showRight: true,
      index: 0, // index of first card that showing on the page
      offset: 0, // px to transform
      showRightArrow: true,
      showLeftArrow: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.showSlideHandler.bind(this));

    if (
      document.querySelector('.tab-links-list') &&
      document.querySelector('.tab-links-list').scrollLeft > 0
    ) {
      this.setState({
        showLeftArrow: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.showSlideHandler.bind(this));
  }

  showSlideHandler() {
    if (this.tabsEl) {
      const showSlider = this.tabsEl.scrollWidth > this.tabsEl.clientWidth;

      this.setState({
        showSlider: showSlider,
      });
    }
  }

  scrollLeft = (e) => {
    const tabListContainer = document.querySelector('.tab-links-list');
    const tabList = document.querySelector('.tab-links-list > ul.list-reset');
    const scrollStep = tabListContainer.clientWidth / tabList.children.length;

    tabListContainer.scroll({
      left: tabListContainer.scrollLeft - scrollStep,
      behavior: 'smooth',
    });

    if (
      tabListContainer.scrollLeft <
      tabListContainer.scrollWidth - tabListContainer.clientWidth + scrollStep
    ) {
      this.setState({
        showRightArrow: true,
      });
    }
    if (
      tabListContainer.scrollLeft === 0 ||
      tabListContainer.scrollLeft < scrollStep
    ) {
      this.setState({
        showLeftArrow: false,
      });
    }
  };

  scrollRight = (e) => {
    const tabListContainer = document.querySelector('.tab-links-list');
    const tabList = document.querySelector('.tab-links-list > ul.list-reset');
    const scrollStep = tabListContainer.clientWidth / tabList.children.length;

    tabListContainer.scroll({
      left: tabListContainer.scrollLeft + scrollStep,
      behavior: 'smooth',
    });

    if (
      tabListContainer.scrollLeft >=
      tabListContainer.scrollWidth - tabListContainer.clientWidth - scrollStep
    ) {
      this.setState({
        showRightArrow: false,
      });
    }

    if (tabListContainer.scrollLeft > 0 - scrollStep) {
      this.setState({
        showLeftArrow: true,
      });
    }
  };

  render() {
    const children = React.Children.toArray(this.props.children);
    const isTouch = mobileDetect();

    return (
      <section
        className={`epg-tabs-nav flex flex-no-wrap items-center overflow-x-hidden relative ${this
          .props.className || ''}`}
      >
        <div
          className={`tab-links-list max-w-full overflow-y-hidden scrolling-touch w-full px-2 ${
            !isTouch ? 'overflow-x-hidden' : ''
          }`}
        >
          {!isTouch &&
          this.state.showLeftArrow &&
          this.state.showSlider &&
          children.length > 0 ? (
            <div
              onClick={this.scrollLeft}
              className='left-arrow-wrap w-64 md:w-128 h-full flex items-center absolute pin-l top-0 cursor-pointer flex-none os-semicircle os-semicircle_previous'
            >
              {!isTouch && (
                <BackIcon
                  width={48}
                  height={48}
                  color='white'
                  className='absolute z-1'
                />
              )}
            </div>
          ) : null}
          <ul
            className={`w-full list-reset inline-flex flex-no-wrap leading-base ${
              isTouch ? 'scrolling-touch ' : ' '
            } ${this.props.listClassName || ''}`}
            ref={(node) => {
              this.tabsEl = node;
              const localScrollWidth = this.tabsEl && this.tabsEl.scrollWidth;

              if (
                !this.initialResize ||
                (localScrollWidth &&
                  localScrollWidth !== this.currentScrollWidth)
              ) {
                this.initialResize = true;
                this.currentScrollWidth = localScrollWidth;
                this.showSlideHandler();
                this.setState({
                  showLeftArrow: false,
                  showRightArrow: true,
                });
              }
            }}
          >
            {children}
          </ul>
          {!isTouch &&
          this.state.showRightArrow &&
          this.state.showSlider &&
          children.length > 0 ? (
            <div
              onClick={this.scrollRight}
              className='right-arrow-wrap w-64 md:w-128 h-full flex items-center absolute pin-r top-0 hover:text-teal cursor-pointer flex-none os-rotate-180 os-semicircle os-semicircle_previous'
            >
              {!isTouch && (
                <BackIcon
                  width={48}
                  height={48}
                  color='white'
                  className='absolute z-1'
                />
              )}
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  listClassName: PropTypes.string,
  style: PropTypes.object,
};

Carousel.defaultProps = {
  children: [],
  className: '',
  listClassName: '',
  style: {},
};

export default Carousel;
