import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from './ui-kit/Icons/InfoIcon';

class HelpSupportButton extends Component {
  render() {
    return (
      <span
        className={`${
          this.props.absolute ? 'absolute' : 'fixed'
        } bg-medium-dark-grey bottom-0 pin-r no-underline hover:bg-teal cursor-pointer z-500`}
        onClick={this.props.onClick}
      >
        <div className='w-48 sm:w-180 h-48 font-MarkProHeavy text-sm text-white flex items-center justify-center hover:text-black'>
          <p className='hidden sm:block'>Help & Support</p>
          <InfoIcon />
        </div>
      </span>
    );
  }
}

HelpSupportButton.propTypes = {
  absolute: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default HelpSupportButton;
