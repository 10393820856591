import React from 'react';

const TeamPlay = ({ passDirection, passAccuracy, statsList }) => (
  <div className='w-full flex flex-wrap bg-widget-grey px-15 pt-16 pb-8 xl:pb-16'>
    <div className='w-full text-center h-16 mb-16'>
      <span className='text-white text-base font-MarkProHeavy leading-none'>
        Team Play
      </span>
    </div>
    <div
      className={`w-full flex flex-wrap ${
        !passDirection ? 'items-center justify-center' : ''
      } md:flex-no-wrap border-none`}
    >
      {passDirection ? (
        <div className='h-256 w-full md:w-50pminus3 xl:md:w-50pminus6 mr-0 md:mr-3 xl:mr-6 mb-8 md:mb-0 border-b border-medium-dark-grey bg-solid md:border-none'>
          {passDirection}
        </div>
      ) : null}
      <div className='h-256 w-full md:w-50pminus3 xl:md:w-50pminus6 ml-0 md:ml-3 xl:ml-6 border-b border-medium-dark-grey bg-solid md:border-none'>
        {passAccuracy}
      </div>
    </div>
    <div className='w-full'>{statsList}</div>
  </div>
);

export default TeamPlay;
